.group_page {
  overflow: hidden;
}

.group_page .group_summary {
  position: relative;
  height: 128px;
}

@media (min-width:768px) {
  .group_page .group_summary {
    height: 192px;
  }
}

.group_page .group_summary .cover > * {
  position: absolute;
}

.group_page .group_summary .shade {
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:#999999;
  opacity: 0.7;
}

.group_page .group_summary .cover img {
  width: auto;
}

.group_page .group_summary .info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.5em;
  padding-left: 1.5em;
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  overflow: hidden;
}

.group_page .group_summary .info ul{
  list-style-type: none;
}

.group_page .group_summary .access {
  display: flex;
  flex-direction: column;
}

.group_page .group_summary .join_button {
  border: none;
  text-decoration: underline;
  cursor: pointer;
    
  background: none;
  color: #fff;
  text-transform: lowercase;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.group_page .group_summary .unauthenticated_join_button {
  background: none;
  color: #fff;
  text-transform: lowercase;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.5em;
  margin-right: 0.5em;
  border-bottom: 0.01em solid #fff;
}

.link {
  margin-left: 0.5em;
  margin-right: 0.5em;
  text-decoration: underline;
}

.group_page .closed {
  color: #600;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.close_dialog {
  display:flex;
  flex-direction: column;
  padding: 1em;
}

.close_dialog .close_content {
  display:flex;
  flex-direction: column;
}

.close_dialog .close_important_message {
  margin-top:2em;
  margin-bottom: 2em;
}

.close_dialog .outcome_to_be_pinned {

}

.close_dialog .outcome_title {
  margin: 2em;
  font-size: 1.25em;
  font-weight: normal;
  font-style: normal;
}

.close_dialog .buttons {
  display:flex;
  justify-content: flex-end;
}
