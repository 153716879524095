.activity_list {
background-color: #F0F2F5;
}

.group_activity_list h3 {    
    font-size: 14px;
    font-weight: normal;
  }
  
  .group_activity_list h1 {    
    font-size: 16px;
    line-height: 18px;    
    font-weight: normal;
  }
  
  .group_activity_list section {
    margin-top: 0.1em;
    margin-bottom: 0.005em;
    background-color: #fff;
  }
  
  