.group_extra_nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0.5em;
}

.group_extra_nav .close_dialog {
  margin: 1em;
  display: flex;
  flex-direction: column;
}

.group_extra_nav .close_dialog span {
  margin-top:1em;
  margin-bottom:1em;
}

.group_extra_nav .close_dialog ol {
  margin: 2em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.group_extra_nav .close_dialog ol li {
  margin-bottom: 1em;
}

.group_extra_nav .close_dialog .buttons {
  margin-top: 1em;
  padding-top: 1.25em;
  border-top: 0.01em solid #4D4D4D;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
